<!--
  - Copyright (C) 2019. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div>
    <b-modal
      id="client-delete"
      size="sm"
      title="Delete!"
      header-bg-variant="danger"
      header-text-variant="light"
      @ok="removeClient(toBeDeleted)"
      ok-title="Yes"
      ok-variant="danger"
    >
      Do you want to delete <b>{{ getClientName(toBeDeleted) }}</b>?
    </b-modal>
    <!-- BEGIN: sidebar client creation -->
    <sidebar class="sidebar" ref="sidebar"
             :title="$t('ems.control.clientDetails')"
             :subtitle="$t('ems.control.enterControl')"
             v-if="isDriverDataComplete">
      <template v-slot:sidebar-content>
        <div  class="text-capitalize">
          <b-form @submit="addClient" id="client-add-form">
            <b-form-group
              :label="$t('ems.common.type') + ':*'"
              label-for="inputClientType"
            >
              <b-form-select id="inputClientType"
                             v-model="selectedDriverAliasId"
                             v-on:change="onSelectedDriverChanged"
                             :options="driverSelectList"
                             required>
              </b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('ems.common.name') + ':*'"
              label-for="inputname"
            >
              <b-form-input
                id="inputname"
                :placeholder="$t('ems.common.enterName')"
                v-model="clientName" required
              >
              </b-form-input>
            </b-form-group>
            <b-form-group v-for="i of inputValues" :key="i.name"
                          :label="$t('ems.common.' + i.name.toLowerCase()) + ':*'"
                          :label-for="'input'+i.name">
              <b-form-select
                v-if="selectedDriver.getFactoryValues(i.name)"
                :value="null" v-model="i.value"
                :options="selectedDriver.getFactoryValues(i.name)"
                required>
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>
                    {{ $t('ems.common.selectType') }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-input
                v-else
                :id="'input'+i.name"
                :placeholder="$t('ems.common.enter' + capitalizeString(i.name))"
                v-model="i.value"
                required
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="success" class="mt-5"
                      id="submit-client-button" block>
              {{ $t('ems.control.saveClient') }}
            </b-button>
          </b-form>
        </div>
      </template>
    </sidebar>
    <!-- END: sidebar client creation -->
    <!-- Secondary navigation -->
    <navbar-secondary>
      <template v-slot:items>
        <b-nav-item>
          <b-button
            class="show-sidebar-button"
            variant="primary"
            v-on:click="openSideBar()">
            <b-icon-plus></b-icon-plus> {{ $t('ems.control.newClient') }}
          </b-button>
        </b-nav-item>
      </template>
    </navbar-secondary>
    <!-- BEGIN: content -->
    <b-container class="py-4" fluid>
      <!-- Errors -->
      <b-alert variant="danger" show v-if="driverError">
        <b>Driver error:</b> {{ driverError }}
      </b-alert>

      <div class="search-wrapper mb-5">
        <input type="text" v-model="searchQuery" ref="search"
               :placeholder="$t('ems.common.filterNameType')"/>
      </div>
      <!-- Table content -->
      <b-table striped hover :items="resultQuery" v-if="isDriverDataComplete"
               id="data-table" :per-page="perPage" :fields="tableFields"
               :current-page="currentPage" ref="client-table">
        <template v-slot:cell(actions)="row">
          <b-button variant="dark" size="sm"
                    :to="{ name: 'clientCtrl', params: { client_id: row.value.id }}">
            <b-icon-controller></b-icon-controller>
          </b-button>
          <b-button
            class="ml-1"
            variant="danger" size="sm"
            v-on:click="onDeleteClient(row.value)"
          >
            <b-icon-trash></b-icon-trash>
          </b-button>
        </template>
        <template v-slot:cell(health)="row">
          <b-icon-circle-fill
            v-if="row.item.health === HEALTH_STATUS_SERVING"
            variant="success"
          ></b-icon-circle-fill>
          <b-icon-circle-fill
            v-else-if="row.item.health === HEALTH_STATUS_NOT_SERVING"
            variant="warning"
          ></b-icon-circle-fill>
          <b-icon-circle-fill
            v-else-if="row.item.health === HEALTH_STATUS_SERVICE_UNKNOWN"
            variant="danger"
          ></b-icon-circle-fill>
          <b-icon-circle-fill
            v-else
            variant="secondary"
          ></b-icon-circle-fill>
        </template>
        <template v-slot:cell(status)="row">
          <b-badge
            v-if="row.item.status"
            :variant="row.item.status.toLowerCase().includes('error') ? 'danger' : row.item.status.toLowerCase().includes('unknown') ? 'secondary' : 'success'"
          >
            {{row.item.status}}
          </b-badge>
        </template>
      </b-table>

      <div v-if="!resultQuery.length && !loading" class="text-center">
        {{ $t('ems.common.nothingFound') }}
      </div>

      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="data-table" align="center" class="mt-5">
      </b-pagination>
    </b-container>
    <b-alert
      v-model="loading"
      class="position-fixed fixed-bottom m-0 rounded-0 text-center"
      style="z-index: 2000;"
      variant="primary">
      <b-icon-alarm></b-icon-alarm> {{ $t('ems.common.loadingWait') }}
    </b-alert>
    <!-- END: content -->
  </div>
</template>

<script>
  import { captalize } from '../js/utils'

  export default {
    name: 'control',
    components: {
      NavbarSecondary: () => import('@/components/NavbarSecondary.vue'),
      Sidebar: () => import('@/components/Sidebar.vue')
    },

    data () {
      return {
        loading: false,
        driverError: null,
        clients: [],
        drivers: [],
        isDriverDataComplete: false,
        searchQuery: null,
        perPage: 20,
        currentPage: 1,
        tableFields: [
          {
            key: 'name',
            label: this.$t('ems.control.client'),
            sortable: true
          },
          {
            key: 'type',
            label: this.$t('ems.control.driverType'),
            sortable: true,
            thClass: 'd-none d-lg-table-cell',
            tdClass: 'd-none d-lg-table-cell'
          },
          {
            key: 'info',
            label: this.$t('ems.control.driverInfo'),
            sortable: true,
            thClass: 'd-none d-xl-table-cell',
            tdClass: 'd-none d-xl-table-cell'
          },
          {
            key: 'health',
            label: this.$t('ems.common.health'),
            sortable: true
          },
          {
            key: 'status',
            label: this.$t('ems.common.status'),
            sortable: true
          },
          {
            key: 'actions',
            label: '',
            thClass: 'text-right',
            tdClass: 'text-right'
          }
        ],
        driverAliases: {},
        driverSelectList: null,
        selectedDriverAliasId: null,
        selectedDriverName: null,
        isSidePanel: false,
        inputValues: [],
        clientName: '',
        toBeDeleted: null
      }
    },
    watch: {
      isDriverDataComplete () {
        this.$refs.search.focus()
      }
    },
    computed: {
      HEALTH_STATUS_SERVING () {
        return this.$driverManager.HEALTH_STATUS_SERVING
      },
      HEALTH_STATUS_NOT_SERVING () {
        return this.$driverManager.HEALTH_STATUS_NOT_SERVING
      },
      HEALTH_STATUS_SERVICE_UNKNOWN () {
        return this.$driverManager.HEALTH_STATUS_SERVICE_UNKNOWN
      },
      isDataComplete () {
        return this.isDriverDataComplete && this.clients.length
      },
      tableData () {
        let tableData = []
        this.clients.forEach(c => {
          tableData.push({
            name: c.clientName,
            info: this.getDriverById(c.driverId).info,
            type: this.getDriverAlias(c),
            health: c.health,
            status: c.status,
            actions: { id: c.clientId }
          })
        })
        return tableData
      },
      resultQuery () {
        if (this.searchQuery) {
          return this.tableData.filter(item => {
            return this.searchQuery
              .toLowerCase().split(' ')
              .every(v => item.name.toLowerCase().includes(v) ||
                item.type.toLowerCase().includes(v))
          })
        } else {
          return this.tableData
        }
      },
      rows () {
        return this.resultQuery.length
      },
      selectedDriver () {
        return this.getDriverByAliasId(this.selectedDriverAliasId)
      }
    },

    methods: {
      update () {
        this.loading = true
        this.$driverManager.updateBrokerData()
          .then(async () => {
            this.updateDrivers()
            await this.updateClients()
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.driverError = err
          })
      },

      async updateClients () {
        let clients = this.$driverManager.clients
        clients.forEach(c => {
          c.status = null
          c.health = null
        })
        this.driverAliases = {}
        for (let d of clients) {
          let driver = this.getDriverById(d.driverId)
          let alias = await this.$driverManager.computeDriverAlias(d, driver)
          if (alias) {
            this.driverAliases[d.clientId] = alias
          }
          this.updateStatus(d)
        }
        this.clients = clients
      },

      updateDrivers () {
        this.drivers = this.$driverManager.drivers
        let drivers = []
        for (let driver of this.drivers) {
          drivers.push(
            { value: driver.alias + driver.driverId, text: driver.alias }
          )
        }
        this.driverSelectList = drivers
        this.selectedDriverAliasId = this.driverSelectList[0].value
        this.selectedDriverName = this.driverSelectList[0].text
        this.isDriverDataComplete = true
        this.onSelectedDriverChanged()
      },

      getClientName (clientId) {
        if (clientId) {
          return this.clients.find(d => d.clientId === clientId).clientName
        }
        return null
      },

      getDriverById (driverId) {
        return this.drivers.find(d => d.driverId === driverId)
      },

      getDriverByAliasId (aliasId) {
        return this.drivers.find(
          d => aliasId.includes(d.driverId) && aliasId.includes(d.alias)
        )
      },

      getDriverAlias (client) {
        if (client.clientId in this.driverAliases) {
          return this.driverAliases[client.clientId]
        }
        return this.getDriverById(client.driverId).name
      },

      openSideBar () {
        this.onSelectedDriverChanged()
        this.$refs.sidebar.open()
      },

      onSelectedDriverChanged () {
        this.clientName = ''
        this.inputValues = []
        let fixedParameter = this.selectedDriver.fixedFactoryParameter
        for (let key in this.selectedDriver.factoryParameter) {
          if (fixedParameter && (key in fixedParameter)) {
            continue
          }
          this.inputValues.push({ name: key, value: '' })
        }
        this.$forceUpdate()
      },

      onDeleteClient (client) {
        this.toBeDeleted = client.id
        this.$bvModal.show('client-delete')
      },

      updateStatus (client) {
        let driver = this.getDriverById(client.driverId)
        let statusActionName = driver.getStatusEventName()
        if (statusActionName) {
          this.$driverManager.sendAction(
            statusActionName,
            client,
            driver,
            null
          ).then(status => {
            if (status) {
              client.status = driver.getStatusString(status.syncStatus)
            }
          }).catch(err => {
            client.status = 'STATUS_UNKNOWN'
            console.error(err)
          })
        }

        statusActionName = driver.getHealthEventName()
        if (statusActionName) {
          this.$driverManager.sendAction(
            statusActionName,
            client,
            driver,
            null
          ).then(status => {
            if (status) {
              client.health = driver.getHealthStatusString(status.status)
            }
          }).catch(err => {
            console.error(err)
          })
        }
      },

      onStatusUpdate (data) {
        let cIndex = this.clients.findIndex(c => data.clientId === c.clientId)
        if (cIndex !== -1) {
          let client = this.clients[cIndex]
          if (data.status.isHealthStatus) {
            client.health = data.status.uiStatus
          } else {
            client.status = data.status.uiStatus
          }
          this.$set(this.clients, cIndex, client)
        }
      },

      removeClient (clientId) {
        this.toBeDeleted = null
        this.loading = true
        return this.$driverManager.deleteClient(clientId)
          .then(() => {
            this.update()
          })
          .catch((err) => {
            console.error(err)
            this.loading = false
            this.clientError = err
          })
      },

      addClient (evt = null) {
        if (evt) {
          evt.preventDefault()
        }
        let values = {}
        for (let i of this.inputValues) {
          values[i.name] = i.value
        }
        let fixedParameter = this.selectedDriver.fixedFactoryParameter
        if (fixedParameter) {
          values = Object.assign(values, fixedParameter)
        }

        this.$refs.sidebar.close()
        return this.$driverManager.createClient(
          this.clientName, this.selectedDriver, values)
          .then(() => {
            this.update()
          })
          .catch((error) => {
            console.error(error)
            this.clientError = error
          })
      },

      capitalizeString (s) {
        return captalize(s)
      }
    },

    created () {
      this.update()
      this.$driverManager.$on('client-update', this.onStatusUpdate)
    },

    beforeDestroy () {
      this.$driverManager.$off('client-update', this.onStatusUpdate)
    }
  }
</script>

<style scoped>
</style>
